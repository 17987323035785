<template>
  <div class="container">
    <iframe class="iframe" :src="url" frameborder="0"></iframe>
  </div>
</template>

<script>
import { installPaymentAppActive } from '@/api/plugin';
export default {
  data() {
    return {
      url: 'https://developer.shopline.com/docsv2/ec20/cdb180dc069f7d8d6877c0cdffe96f73/OJ4KK8vE',
    };
  },
  mounted() {
    const { url, appName, data } = this.$route.query;
    this.url = url;
    this.active(appName, data);
  },
  methods: {
    active(appName, data) {
      installPaymentAppActive('shopline', appName, data).then((res) => {
        // if (res.respCode === '20000') {
        //   this.$router.push({
        //     path: '/v1/plugin/appInstall/shopline/success',
        //     query: this.$route.query,
        //   });
        // } else {
        //   this.$router.push({
        //     path: '/v1/plugin/appInstall/shopline/failed',
        //     query: { m: res.respMsg || '' },
        //   });
        // }
        // this.fullscreenLoading = false;
      }).catch(() => {
        // this.$router.push({
        //   path: '/v1/plugin/appInstall/shopline/failed',
        // });
        // this.fullscreenLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
}
.iframe {
  width: 100%;
  height: 100%;
}
</style>
